import React from 'react';
import { runApp } from '@vs/vsf-boot';
import routes from '@/router';
import * as stores from '@/stores/index';

import '@vs/vsf-kit/dist/umd/index.css';
import './style/global.less';
import './style/reset.less';
import './style/vars.less';

runApp({
  boot: {
    mountElement: document.querySelector('#root'),
    qiankun: false,
  },

  stores,

  router: {
    type: 'browser',
    config: routes,
  },
});
