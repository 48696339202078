import Root from "@/layouts";
import pageRoutes from "./routes";

const routes = [
    {
        path: "/",
        component: Root,
        routes: pageRoutes,
    },
];

export default routes;
