import { defineStore } from '@vs/vsf-boot';

const demo = defineStore({
    // state
    num: 0,

    // action
    increment: () => demo.num++,

    decrement: () => {
        demo.num--;
    },
});

export default demo;
