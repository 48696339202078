import Index from '@/pages/home/index';
import company from '@/pages/company/index'
const routes = [
    {
        path: '/',
        exact: true,
        component: Index,
    },
    {
        path: '/company',
        exact: true,
        component: company,
    },
    
];

export default routes;
