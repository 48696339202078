import React, { useRef, useState } from 'react';
import { Layout } from '@vs/vsf-kit';
import { renderRoutes } from '@vs/vsf-boot';
import { Anchor } from 'antd';
import logo from '@/pages/home/img/logo.jpg';
import logo1 from '@/pages/home/img/boss_icon.png';
import './index.less';

const { Sider } = Layout as any;
const tapItemList = [
  { name: '首页', address: '/' },
  { name: '关于公司', address: '/company' },
  // { name: '联系我们', address: '/contact' },
];
const Container: any = props => {
  const { route } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [itemType, setItemType] = useState('/');
  const myRef = useRef(null)
  return (
    <div className="vsf-layout">
      <div className="vsf-layout-header vsf-text-20">
        <img className="home_top_img" src={logo}></img>
        <div className="home_top_tap">
          {tapItemList?.map((v, i) => {
            return (
              <div
                className="home_top_tap_item"
                style={{ color: itemType === v.address ? '#1b418b' : '#000' }}
                key={i}
                ref={myRef}
                onClick={() => {
                  setItemType(v.address);
                    props.history.push(v.address);
                }}
              >
                {v?.name}
              </div>
            );
          })}
          <div>
          <a className="home_top_tap_item" href='#foot'>联系我们</a>
          </div>
        </div>
      </div>
      <div className="vsf-layout-content">
        <div className="vsf-layout-main">{renderRoutes(route.routes)}</div>
      </div>
    </div>
  );
};

export default Container;
