import React from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { Layout, Page, Section } from '@vs/vsf-kit';
import { Carousel } from 'antd';
import home from './img/home.webp'
import home1 from './img/home1.jpg'
import './index.less';
const Index = () => {
  const onChange = currentSlide => {
    console.log(currentSlide);
  };
  return (
    <div className="home_content">
      <div className="home_content_Carousel">
        <Carousel  autoplay>
          <div className="home_content_img">
            <img src={home}></img>
          </div>
          <div className="home_content_img">
          <img src={home1}></img>
          </div>
        </Carousel>
      </div>
      <div className="home_content_major">
        <div className="home_content_major_english">Professional Team</div>
        <div className="home_content_major_title">专业团队</div>
        <div className="home_content_major_text">
          我们拥有专业化的应用开发服务团队，具备丰富的海量业务开发运营经验。团队凭借多年在BAT负责多款海量业务的项目经验，形成了系统化、成熟的方法论，为客户提供更高的IT投资回报。
        </div>
      </div>
      <div className="home_content_company" id='company'>
        <div className="home_content_major_english">About Company</div>
        <div className="home_content_major_title">关于公司</div>
        <div className="home_content_major_text">
          我们拥有专业化的应用开发服务团队，具备丰富的海量业务开发运营经验。团队凭借多年在BAT负责多款海量业务的项目经验，形成了系统化、成熟的方法论，为客户提供更高的IT投资回报。
        </div>
      </div>
      <div className="home_content_foot_bg" id="foot">
        <div className="home_content_foot">
          <div className="home_content_foot_address">
            <div className="home_content_foot_address_name">
            杭州亿零而思信息科技有限公司
            </div>
            <div className="home_content_foot_address_address">
              <span className="home_content_foot_address_address_title">
                地址：
              </span>
              <span className="home_content_foot_address_address_content">
                浙江省杭州市萧山区天人大厦
              </span>
            </div>
            <div className="home_content_foot_address_address">
              <span className="home_content_foot_address_address_title">
                电话：
              </span>
              <span className="home_content_foot_address_address_content">
                17857668888
              </span>
            </div>
            <div className="home_content_foot_address_address">
              <span className="home_content_foot_address_address_title">
                邮箱：
              </span>
              <span className="home_content_foot_address_address_content">
                business@teitui.com
              </span>
            </div>
          </div>
          <div className="home_content_foot_company">
            <div className="home_content_foot_company_name">关于公司</div>
            <div className="home_content_foot_company_name1">公司简介</div>
            <div className="home_content_foot_company_name1">公司历程</div>
            <div className="home_content_foot_company_name1">公司治理</div>
          </div>
          <div className="home_content_foot_new">
            <div className="home_content_foot_new_name">新闻中心</div>
            <div className="home_content_foot_new_name1">公司新闻</div>
            <div className="home_content_foot_new_name1">企业动态</div>
            <div className="home_content_foot_new_name1">行业资讯</div>
          </div>
          <div className="home_content_foot_business">
            <div className="home_content_foot_business_name">业务领域</div>
            <div className="home_content_foot_business_name1">产业布局</div>
            <div className="home_content_foot_business_name1">科技创新</div>
            <div className="home_content_foot_business_name1">产品创新</div>
          </div>
        </div>
        <div className="home_content_foot_RecordNo">
          <div>
            {`Copyright ${(new Date()).getFullYear()}.All Rights Reserved Theme by 杭州亿零而思信息科技有限公司.`}
            浙ICP备2021009910号
          </div>
        </div>
      </div>
    </div>
  );
};

export default definePage(Index);
